export enum PunishmentType {
  Ban = 0,
  Mute = 1
}

export enum NewsType {
  NONE = 'NONE',
  FEATURED = 'FEATURED',
  PATCH = 'PATCH',
  SHOP = 'SHOP'
}

export enum SearchType {
  PLAIN_TEXT = 'PlainText',
  DISCORD_ID = 'DiscordId',
  ACCOUNT_ID = 'AccountId',
  CHARACTER_NAME = 'CharacterName',
  CHARACTER_ID = 'CharacterId',
  HARDWARE_ID = 'HardwareId',
  IP_ADDRESS = 'IpAddress',
  MASTER_ACCOUNT_ID = 'MasterAccountId',
}

export enum PaymentProviders {
  STRIPE = 'Stripe',
  MOLLIE = 'Mollie'
}

export enum TransactionStatusEnum {
  OPEN = 'Open',
  PENDING = 'Pending',
  PAID = 'Paid',
  FAILED = 'Failed',
  CANCELED = 'Canceled',
  EXPIRED = 'Expired'
}

export enum DeviceType {
  DESKTOP = 'Desktop',
  TABLET = 'Tablet',
  MOBILE = 'Mobile'
}

export enum AdventCalendarModalType {
  OUTDATED = -2,
  ANSWERED = -1,
  CURRENT,
  INCOMING
}