




























import { GM } from '@/api'
import { Component, Vue } from 'vue-property-decorator'
import { GameModule } from '@/store/modules'
import { NUMBERS_ONLY_REGEX } from '@/services/Validation/regexes'
import { SearchType } from '@/types/enums'
import { CharacterFactory } from '@/services/Factories'

@Component({
  components: {
    CharacterStatCard: () => import('./CharacterStatCard.vue')
  }
})
export default class SearchCharacterByIdModal extends Vue {
  @GameModule.Mutation setViewCharacter

  showCharacterViewerModal = false
  characterField = ''
  error = false

  mounted() {
    this.$root.$on('openCharacterViewerSearchModal', () => this.showCharacterViewerModal = true)
  }

  async searchCharacter(e) {
    e.preventDefault()

    try {
      const { character } = await GM.getCharacter({
        search: this.characterField,
        searchType: NUMBERS_ONLY_REGEX.test(this.characterField)
          ? SearchType.CHARACTER_ID
          : SearchType.CHARACTER_NAME
      })

      const buildCharacter = await new CharacterFactory(character).buildCharacter()

      this.setViewCharacter(buildCharacter)
      this.$router.push(`/gm/characters/view/${character.id}`)
    } catch(e) {
      this.error = true
      this.characterField = ''
      console.error(`Error when trying to fetch character ${this.$route.params.characterField}`, e)
    }
  }
}
